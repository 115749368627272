<template>
  <div>
    <PageHeader :title="FA_LAN.MAIN.QR_TITLE" :salon="salonUsername"/>
    <div class="container all-card-size mt-4">
      <Loading v-if="loading" class="mt-5"/>
      <div v-else>
        <div v-if="!salonError">
          <h6 class="primary-text text-center">{{ salon.name }}</h6>
          <div class="d-flex">
            <img class="col-md-6 col-10 mx-auto" :src="qr">
          </div>
          <div class="d-grid gap-2 mt-4">
            <a class="btn btn-primary" :href="qr" :download="salonUsername+'.png'"
               target="_blank">{{ FA_LAN.BUTTON.DOWNLOAD_QR }}</a>
          </div>
          <router-link class="btn btn-link btn-sm mt-3 grey-text small text-start text-decoration-none"
                       :to="{name:'salon',params:{salon:salonUsername}}">
            {{ FA_LAN.BUTTON.SALON_DETAIL }}
          </router-link>
        </div>
        <p class="primary-text mt-4 mb-1">{{FA_LAN.MAIN.QR_NOTICE_TITLE}}</p>
        <ul class="grey-dark-text small">
          <li>{{FA_LAN.MAIN.QR_NOTICE_1}}</li>
          <li>{{FA_LAN.MAIN.QR_NOTICE_2}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../fa-IR/fa-ir";
import PageHeader from "../components/PageHeader";
import Loading from "../components/Loading";
import {computed, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";

export default {
  name: "QrCode",
  components: {PageHeader, Loading},
  setup() {
    const FA_LAN = persian;
    const loading = ref(true);
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const salonUsername = route.params.salon;
    const salon = computed(() => store.getters["salon/get"]);
    const qr = ref('');
    const salonError = ref(false);

    onMounted(async () => {
      await store.dispatch("salon/getSalon", {userName: salonUsername});

      if (salon.value.userName) {
        getQR()
      } else {
        await router.push({name: "home"});
      }
      loading.value = false;
    })

    function getQR() {
      const qrAPI = "https://qr-code.ir/api/qr-code/?s=10&e=Q&d=";
      const baseSite = location.origin;

      qr.value = qrAPI + baseSite + "/salon/" + salonUsername
    }

    return {FA_LAN, salon, loading, qr, salonError, salonUsername}
  }

}
</script>

<style scoped>

</style>