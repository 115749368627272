const main = {
    GISOON: "برنامه نوبت دهی آرایشگاه گیسون",
    WAIT: "لطفا کمی صبر کنید",
    EMPTY: "متاسفانه نوبتی برای روز درخواستی تعریف نشده است",

    //salon info
    MANAGER_NAME: "مدیریت: ",
    PHONE: "شماره تماس",
    MALE: "(مردانه)",
    FEMALE: "(زنانه)",

    //head
    LOGIN_TITLE: "ثبت نام به عنوان مشتری",
    PROFILE_TITLE: "تکمیل پروفایل",

    //models
    MODEL_TITLE: "نمونه کارها",
    MODEL_EMPTY: "نمونه کاری توسط آرایشگاه تعریف نشده است",
    MODEL_PRICE: "از price تومان",

    //convas
    CONVAS_DESCRIPTION: "رزرو این نوبت شامل پرداخت بیعانه می باشد. پس از پرداخت موفق مبلغ پرداختی به حساب آرایشگاه واریز خواهد شد. آیا از ثبت رزرو برای ساعت و تاریخ انتخابی اطمینان دارید؟",
    CONVAS_TITLE: "مبلغ بیعانه price تومان",

    //images
    EMPTY_IMAGES: "عکسی برای تمونه کار توسط آرایشگاه اضافه نشده است",

    //short link
    LINK_NOT_FOUND: "لینک مورد نظر یافت نشد",
    TYR_AGAIN: "خطایی رخ داد، لطفا مجددا تلاش کنید",
    WAITING_TO_REDIRECT: "درحال انتقال به صفحه مورد نظر",

    //about us
    ABOUT_US_TITLE: "درباره ما",
    ABOUT_US_DES: "ما عاشق ارائه بهترین راهکارها برای رونق کسب و کارهای مختلف هستیم. طراحی سایت کارآمد و سامانه های مختلف آنلاین تخصص ماست و تلاش می کنیم تا بهترین محصولات را برای مشتریانمان تولید نماییم.",
    DESIGNED_BY: "طراحی و توسعه داده شده توسط گروه نگاه",
    WEBSITE_NEGAH: "وب سایت گروه نگاه",
    WEBSITE_GISOON: "وب سایت گیسون",
    WHAT_IS_GISOON: "درباره گیسون بیشتر بدانید",
    CONTACT_US_TITLE: "تماس با ما",
    SUPPORT_PHONE: "تماس با پشتیبانی",
    APP_PHONE: "طراحی نسخه اختصاصی",

    //salon guid
    SALON_GUID_TITLE: "آرایشگاه هستم",
    HOW_SALON_SUBMIT: "آرایشگاه خودم را چگونه در گیسون ثبت کنم؟",
    HOW_SALON_SUBMIT_ANSWER: "کافی است نسخه اندروید گیسون را نصب و در کمتر از 2 دقیقه ثبت نام خود را تکمیل کنید",
    HOW_SALON_SUBMIT_ANSWER_AUTO: "تمامی فرایندها اتوماتیک و ساده است! پس همین الان به خانواده بزرگ گیسون بپیوندید",
    SALON_HELP: "سوالی دارید؟ با ما در ارتباط باشید",

    GISOON_FEATURES_TITLE: "گیسون چه امکاناتی به من می دهد؟",
    GISOON_FEATURES_1: "در هر ساعت شبانه روز مشتریان می تونن ازتون نوبت بگیرن",
    GISOON_FEATURES_2: "نمونه کارهای خودتون را به مشتریان نشان دهید",
    GISOON_FEATURES_3: "برای رزرو نوبت می تونید پرداخت بیعانه تعریف کنید",
    GISOON_FEATURES_4: "همیشه لیست مشتریان و گزارش نوبت ها و مراجعاتشون رو داشته باشید",
    GISOON_FEATURES_5: "به صورت اتوماتیک نوبت ها رو به مشتریان یاداوری می کنیم",
    GISOON_FEATURES_6: "و کلی امکانات باحال دیگه",

    //qr code
    QR_TITLE: "کد QR صفحه شخصی آرایشگاه",
    QR_NOTICE_TITLE: "نکات مهم:",
    QR_NOTICE_1: "حتما قبل از چاپ کد QR بر روی کارت ویزیت یا پوستر و غیره آن را تست نمایید",
    QR_NOTICE_2: "در صورت تغییر نام کاربری آرایشگاه، آدرس صفحه شخصی و در نتیجه کد QR تغییر میکند",

    //profile
    PROFILE_MAIN_TITLE: "حساب کاربری",
    TOMAN:"تومان",
    FULL_PROFILE:"برای مشاهده کامل اطلاعات حساب کاربری خود شامل مشاهده نوبت‌های رزرو شده، تاریخچه تراکنش‌ها، ویرایش اطلاعات، تنظیمات یادآوری نوبت و غیره از نسخه اندروید استفاده نمایید",
}

export default main;