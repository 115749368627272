import {post} from "../mainRoute";
import {sweetAlert} from "../../js/alert/sweetAlert";
import persian from "../../fa-IR/fa-ir";

export const salon = {
    namespaced: true,
    state: {
        salon: {}
    },
    mutations: {
        set(state, salon) {
            state.salon = salon
        },
        remove(state) {
            state.salon = {}
        }
    },
    getters: {
        get(state) {
            return state.salon
        },
    },
    actions: {
        async getSalon(context, userName) {
            await post("v1/person/getSalonProfile", {userName: userName})
                .then(function (response) {
                    if (response.data.code !== 200) {
                        sweetAlert('error', response.data.description)
                    } else {
                        if (response.data.data) {
                            context.commit("set", response.data.data[0]);
                        }else{
                            sweetAlert('error', persian.FORM.SALON_NOT_FOUND)
                        }
                    }
                })
                .catch(function (error) {
                    sweetAlert('error', error)
                })
        }
    }
}