<template>
  <div>
    <PageHeader :title="FA_LAN.MAIN.MODEL_TITLE" :salon="salon.userName"/>
    <div class="container all-card-size mt-3" ref='scrollComponent'>
      <p v-if="empty">{{ FA_LAN.MAIN.MODEL_EMPTY }}</p>
      <div v-else>
        <div class="row m-0 model-max-height bg-white" v-for="model in models" :key="model.id">
          <div class="col-5 container-image-text ">
            <img :src="model.image"/>
            <div v-if="model.price" class="bottom-center primary-btn-background small primary-text ps-2 pe-2 rounded-3 ">
              {{ priceText(model.price) }}
            </div>
          </div>
          <div class="col-7 p-2">
            <div>
              <p class="primary-text m-0">{{ model.title }}</p>
              <p class="grey-dark-text p-0 small ">{{ model.description }}</p>
            </div>
            <div class="d-flex justify-content-end align-items-end">
              <router-link class="btn btn-primary btn-sm p-2"
                           :to="{name:'salonModelImage',params:{salon:salon.userName,model:model.id}}">
                {{ buttonText(model.imageCount) }}
              </router-link>
            </div>
          </div>
          <hr class="mt-2 grey-text">
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center mt-3 mb-3">
      <div class="spinner-border" role="status" v-if="moreLoader"></div>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, onUnmounted, ref} from "vue";
import persian from "../../fa-IR/fa-ir";
import {useStore} from "vuex";
import PageHeader from "../../components/PageHeader";
import {numberWithCommas} from "../../js/number";

export default {
  name: "Model",
  components: {PageHeader},
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    const scrollComponent = ref(null);
    const moreLoader = ref(true);
    const index = ref(0);
    const models = computed(() => store.getters["model/get"]);
    const salon = computed(() => store.getters["salon/get"]);
    const empty = ref(false);

    onMounted(async () => {
      await getModels()

      window.addEventListener('scroll', loadMore);
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', loadMore);
    })

    async function loadMore() {
      let element = scrollComponent.value
      if (element.getBoundingClientRect().bottom < window.innerHeight) {

        if (index.value - models.value.length > 10) { //for stop looping
          return;
        }
        moreLoader.value = true;
        index.value += 10;
        await getModels();
      }
    }

    async function getModels() {
      await store.dispatch("model/getModel", {
        userName: salon.value.userName,
        index: index.value
      })

      checkEmpty();
      moreLoader.value = false;
    }

    function checkEmpty() {
      empty.value = models.value.length === 0;
    }

    function buttonText(count) {
      const text = FA_LAN.BUTTON.MODEL_IMAGE;

      return text.replace("count", count);
    }

    function priceText(price) {
      const text = FA_LAN.MAIN.MODEL_PRICE;

      return text.replace("price", numberWithCommas(price));
    }

    return {scrollComponent, FA_LAN, salon, moreLoader, empty, models, buttonText, priceText}
  }
}
</script>

<style scoped>

</style>