<template>
  <router-view v-slot="{ Component }">
    <transition name="slide-fade">
      <component :is="Component"/>
    </transition>
  </router-view>
  <Footer/>
</template>

<script>
import Footer from "./components/Footer";
export default {
  name: 'App',
  components: {Footer},
}
</script>

<style>
.slide-fade-enter-active {
  transition: all .8s ease;
}

.slide-fade-enter-from {
  transform: translateX(20px);
  opacity: 0;
}

.loader {
  left: 50%;
  margin-left: -4em;
}
</style>
