<template>
  <div>
    <div class="primary-background text-white p-2 pb-3 pt-3">
      <div class="container text-center all-card-size">
        <p class="m-0">{{ FA_LAN.MAIN.GISOON }}</p>
      </div>
    </div>
    <div class="container all-card-size mt-5 all-card-size">
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.FORM.SALON_USER_NAME }}</label>
        <input type="text" class="form-control" v-model.lazy="salonUsername">
        <small class="text-danger">{{ error }}</small>
      </div>
      <div class="d-grid gap-2 mt-4">
        <button @click="searchSalon" class="btn btn-primary" :disabled="formLoader">
          <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader"></div>
          {{ FA_LAN.BUTTON.SEARCH }}
        </button>
      </div>
    </div>
  </div>


</template>

<script>
import persian from "../fa-IR/fa-ir";
import {ref} from "vue";
import {post} from "../store/mainRoute";
import {sweetAlert} from "../js/alert/sweetAlert";
import {useRouter} from "vue-router";

export default {
  name: "Home",
  setup() {
    const FA_LAN = persian;
    const salonUsername = ref('');
    const router = useRouter();
    const formLoader = ref(false);
    const error = ref('');

    async function searchSalon() {
      if (salonUsername.value === '') {
        error.value = FA_LAN.FORM.EMPTY_USER_NAME;
        return;
      }
      error.value = '';
      formLoader.value = true;

      await post("v1/person/getSalonProfile", {userName: salonUsername.value})
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description)
            } else {
              if (response.data.data) {
                router.push({name: "salon", params: {salon: salonUsername.value}})
              } else {
                error.value = FA_LAN.FORM.SALON_NOT_FOUND;
              }
            }
          })
          .catch(function (error) {
            sweetAlert('error', error)
          })

      formLoader.value = false;
    }

    return {FA_LAN, salonUsername, formLoader, error, searchSalon}
  }
}
</script>

<style scoped>

</style>