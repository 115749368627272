const button = {
    SEARCH:"جست و جو",
    RESERVE:"رزرو نوبت",
    RESERVE_SUBMIT:"رزرو نوبت آرایشگاه",
    MODELS:"نمونه کارها",
    MODEL_IMAGE:"مشاهده تصاویر (count)",
    RESERVE_PAY_CONFIRM:"تایید",
    ANDROID:"دریافت نسخه اندروید",
    DOWNLOAD_QR:"دانلود کد QR",
    RECIEVE_QR:"دریافت کد QR اختصاصی آرایشگاه",
    SALON_DETAIL:"مشاهده آرایشگاه و دریافت نوبت",
    HOME_PAGE:"بازگشت به صفحه اصلی",
    LOGIN:"ورود به عنوان مشتری",
    LOGOUT:"خروج از حساب کاربری",
}

export default button;