<template>
  <div>
    <Loading v-if="loading" class="mt-5"/>
    <router-view v-else v-slot="{ Component }">
      <transition name="slide-fade">
        <component :is="Component"/>
      </transition>
    </router-view>
  </div>
</template>

<script>

import {useStore} from "vuex";
import {onMounted, ref} from "vue";
import Loading from "../../components/Loading";

export default {
  name: "Temp",
  components: {Loading},
  setup() {
    const store = useStore();
    const loading = ref(true);

    onMounted(async () => {
      await store.dispatch('profile/getProfile');

      loading.value = false;
    })

    return {loading}
  }
}
</script>

<style scoped>

</style>