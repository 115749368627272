<template>
  <div class="primary-background text-white p-2 pb-3 pt-3">
    <div class="container all-card-size text-center">
      <span>{{ title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseHeader",
  props: ['title'],

}
</script>

<style scoped>

</style>