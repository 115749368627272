<template>
  <div>
    <Loading v-if="loading" class="mt-5"/>
    <router-view v-else v-slot="{ Component }">
      <transition name="slide-fade">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import Loading from "../../components/Loading";

export default {
  name: "Temp",
  components: {Loading},
  setup() {
    const loading = ref(true);
    const route = useRoute();
    const store = useStore();
    const router = useRouter();
    const salonUsername = route.params.salon;
    const salon = computed(() => store.getters["salon/get"]);
    const salonError = ref(false);

    onMounted(async () => {
      await store.dispatch("salon/getSalon", {userName: salonUsername});
      await store.dispatch('profile/getProfile');

      if (!salon.value.userName) {
        salonError.value = true;
        await router.push({name: "home"})
      }

      loading.value = false;
    })

    return {loading, salonError}
  }
}
</script>

<style scoped>

</style>