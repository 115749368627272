<template>
  <div>
    <div>
      <PageHeader :title="FA_LAN.MAIN.PROFILE_TITLE"/>
    </div>
    <div class="container all-card-size mt-5">
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.FORM.NAME }}</label>
        <input type="text" class="form-control" v-model.lazy="profile.name">
        <small class="text-danger">{{ error.name }}</small>
      </div>
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.FORM.SEX }}</label>
        <div class="row p-1">
          <div class="d-grid gap-2 col-6">
            <input type="radio" class="btn-check" name="sex" id="male" autocomplete="off" value="m"
                   v-model="profile.sex">
            <label class="btn btn-time" for="male"> {{ FA_LAN.FORM.MALE }} </label>
          </div>
          <div class="d-grid gap-2 col-6">
            <input type="radio" class="btn-check" name="sex" id="female" autocomplete="off" value="f"
                   v-model="profile.sex">
            <label class="btn btn-time" for="female"> {{ FA_LAN.FORM.FEMALE }} </label>
          </div>
          <small class="text-danger">{{ error.sex }}</small>
        </div>
      </div>
      <div class="d-grid gap-2 mt-4">
        <button @click="update" class="btn btn-primary" :disabled="formLoader">
          <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader"></div>
          {{ FA_LAN.FORM.CONFIRM_BTN }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../../fa-IR/fa-ir";
import {computed, onMounted, reactive, ref} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import PageHeader from "../../components/PageHeader";
import {post} from "../../store/mainRoute";
import {sweetAlert} from "../../js/alert/sweetAlert";

export default {
  name: "Update",
  components: {PageHeader},
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    const router = useRouter();
    const hasName = computed(() => store.getters["apiKey/hasName"]);
    const salon = computed(() => store.getters["salon/get"]);
    const formLoader = ref(false);
    const successUpdate = ref(false);
    const successMessage = ref('');

    const profile = reactive({
      name: '',
      state: salon.value.state,
      city: salon.value.city,
      sex: '',
      introduced: '',
    })
    const error = reactive({
      status: false,
      name: '',
      sex: ''
    })

    onMounted(() => {
      //TODO add salon
      //if not have salon, or submitted before and login for new salon
      checkHasSalon();
    })

    function checkHasSalon() {
      if (hasName.value) { //if have profile ignore this page
        push();
      }
    }

    function push() {
      if (salon.value.userName) {
        router.push({name: "salonTime", params: {salon: salon.value.userName}})
      } else {
        router.push({name: "home"});
      }
    }

    function checkAllFieldComplete() {
      //empty first
      error.name = '';
      error.sex = '';
      error.status = false;

      if (!profile.name) {
        error.name = FA_LAN.FORM.NAME_EMPTY;
        error.status = true;
      }

      if (!profile.sex) {
        error.sex = FA_LAN.FORM.SEX_EMPTY;
        error.status = true;
      }
    }

    async function update() {
      checkAllFieldComplete();

      if (error.status) {
        return;
      }

      formLoader.value = true;

      //update profile
      await updateProfile();

      if (!successUpdate.value) {//if update fail
        return;
      }

      //add salon
      if (salon.value.userName) {
        await addSalon()
      } else {
        sweetAlert('success', successMessage.value)
        push();
      }
    }

    async function updateProfile() {
      await post("v1/person/updateProfile", profile)
          .then(function (response) {
                if (response.data.code !== 200) {
                  sweetAlert('error', response.data.description)
                } else {
                  successMessage.value = response.data.description;
                  successUpdate.value = true
                }
              }
          )
          .catch(function (error) {
            sweetAlert('error', error)
          })
    }

    async function addSalon() {
      await post("v1/person/addNewSalon", {userName: salon.value.userName})
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description)
            } else {
              sweetAlert('success', response.data.description)
              push();
            }
          })
          .catch(function (error) {
            sweetAlert('error', error)
          })
    }


    return {FA_LAN, formLoader, profile, error, update}
  }
}
</script>

<style scoped>

</style>