import main from "./includes/main";
import form from "./includes/form";
import button from "./includes/button";

const persian = {
    MAIN: main,
    FORM: form,
    BUTTON: button,
}

export default persian;