<template>
  <div>
    <PageHeader :title="salon.name" :salon="salon.userName"/>
    <div class="container all-card-size">
      <h6 class="text-center primary-text mt-4">
        <button class="btn btn-link primary-text font-weight-bold" @click="previousDay">
          <i class="bi bi-chevron-right"></i>
        </button>
        {{ toJalali(date) }}
        <button class="btn btn-link primary-text font-weight-bold" @click="nextDay">
          <i class="bi bi-chevron-left"></i>
        </button>
      </h6>
      <Loading v-if="loading"/>
      <div v-else>
        <p v-if="empty" class="text-center grey-dark-text mt-4">{{ FA_LAN.MAIN.EMPTY }}</p>
        <div v-else class="row m-0">
          <div v-for="time in times" :key="time.id" class="col-4 mt-2 p-1">
            <div class="d-grid gap-2">
              <input type="radio" class="btn-check" name="options" :id="'btn-check'+time.id" autocomplete="off"
                     @click="selectTime(time.id)" :disabled="checkCapacity(time.remaining)">
              <label class="btn btn-time" :for="'btn-check'+time.id">
                {{ manageTimeShow(time.fromTime) }} - {{ manageTimeShow(time.toTime) }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="d-grid gap-2 mx-auto mt-5">
        <div v-if="hasPrice" class="d-grid gap-2">
          <button class="btn btn-primary mt-2" data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom" @click="checkTimeSelect">
            {{ FA_LAN.BUTTON.RESERVE_SUBMIT }}
          </button>
          <Bottom :price="price" id="offcanvasBottom" @reserve="reserve" :loader="formLoader"/>
        </div>
        <button v-else class="btn btn-primary mt-2" @click="reserve" :disabled="formLoader">
          <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader"></div>
          {{ FA_LAN.BUTTON.RESERVE_SUBMIT }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../../components/PageHeader";
import {useStore} from "vuex";
import {computed, onMounted, ref, watch} from "vue";
import {formatDate, gregorian_to_jalali} from "../../js/date/convert";
import {post} from "../../store/mainRoute";
import {sweetAlert} from "../../js/alert/sweetAlert";
import Loading from "../../components/Loading";
import persian from "../../fa-IR/fa-ir";
import router from "../../router";
import Bottom from "../../components/convas/Bottom";

export default {
  name: "Time",
  components: {Bottom, Loading, PageHeader},
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    const salon = computed(() => store.getters["salon/get"])
    const times = ref([]);
    const date = ref(new Date());
    const loading = ref(true);
    const empty = ref(false);
    const timeID = ref('');
    const apiKey = computed(() => store.getters["apiKey/get"])
    const formLoader = ref(false);
    const price = ref(0);
    const hasPrice = ref(false);
    const salonList = ref([]);

    onMounted(async () => {
      await getTime();
      loading.value = false
    })

    function toJalali() {
      return gregorian_to_jalali(formatDate(date.value))
    }

    function nextDay() {
      date.value = new Date(date.value.setDate(date.value.getDate() + 1));
      timeID.value = '';
    }

    function previousDay() {
      date.value = new Date(date.value.setDate(date.value.getDate() - 1))
      timeID.value = '';
    }

    watch(date, () => {
      getTime();
    })

    async function getTime() {
      loading.value = true
      await post("v1/person/getSalonTimes", {
        userName: salon.value.userName,
        date: [formatDate(date.value)]
      })
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description)
            } else {
              times.value = response.data.data;
            }
          })
          .catch(function (error) {
            sweetAlert('error', error)
            console.log(error)
          })
      //reset price
      price.value = 0;
      hasPrice.value = false;

      checkEmpty();
      if (!empty.value) {
        setPrice();
      }

      loading.value = false
    }

    function checkEmpty() {
      empty.value = times.value.length === 0
    }

    function manageTimeShow(time) {
      const timeArray = time.split(":")
      return [timeArray[0], timeArray[1]].join(':');
    }

    function checkCapacity(remaining) {
      return remaining === 0;
    }

    function selectTime(time) {
      timeID.value = time;
    }

    function checkTimeSelect() {
      if (!timeID.value) {
        sweetAlert('error', FA_LAN.FORM.NOT_TIME);
        document.getElementById("offcanvasBottom").style.visibility = "hidden";
      } else {
        document.getElementById("offcanvasBottom").style.visibility = "visible";
      }
    }

    function setPrice() {
      if (times.value[0].depositAmount !== 0) {
        price.value = times.value[0].depositAmount; //first time enough
        hasPrice.value = true;
      }
    }

    async function reserve() {
      if (!timeID.value) {
        sweetAlert('error', FA_LAN.FORM.NOT_TIME);
        return;
      }

      if (!apiKey.value) {
        router.push({name: "login"})
        return;
      }

      formLoader.value = true;

      await getSalon();

      if (!checkSalon()) {
        await addSalon();
      }

      await reserveTime();

      formLoader.value = false;
    }

    async function getSalon() {
      await post("v1/person/getSalonList  ", null)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description)
            } else {
              if (response.data.data) {
                salonList.value = response.data.data;
              }
            }
          })
          .catch(function (error) {
            sweetAlert('error', error)
          })
    }

    async function addSalon() {
      await post("v1/person/addNewSalon", {userName: salon.value.userName})
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description)
            } else {
              //success
            }
          })
          .catch(function (error) {
            sweetAlert('error', error)
          })
    }

    function checkSalon() {
      return salonList.value.find(s => s.userName === salon.value.userName);
    }

    async function reserveTime() {
      await post("v1/person/reserveTime", {
        timeid: timeID.value,
        callBack: location.href,
      })
          .then(function (response) {
            if (response.data.code === 201) {
              location.href = response.data.data.payURL;

            } else if (response.data.code === 200) {
              sweetAlert('success', response.data.description);
              getTime();
            } else {
              sweetAlert('error', response.data.description)
            }
          })
          .catch(function (error) {
            sweetAlert('error', error)
          })
    }

    return {
      salon,
      loading,
      date,
      times,
      empty,
      FA_LAN,
      formLoader,
      price,
      hasPrice,
      checkCapacity,
      selectTime,
      toJalali,
      nextDay,
      previousDay,
      manageTimeShow,
      reserve,
      checkTimeSelect
    }
  }
}
</script>

<style scoped>

</style>