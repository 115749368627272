<template>
  <div>
    <BaseHeader :title="FA_LAN.MAIN.GISOON"/>
    <div class="container all-card-size">
      <div class="mt-5">
        <h6 class="text-center">{{ salon.name }}<span class="grey-text ps-2 small">{{ getSex(salon.sex) }}</span></h6>
        <!--        <p class="text-center grey-dark-text">{{ FA_LAN.MAIN.MANAGER_NAME }}{{ salon.managerName }}</p>-->
        <p class="grey-dark-text mt-3">
          <small>{{ salon.stateName }}- {{ salon.cityName }}- {{ salon.location }}
          </small>
        </p>
        <div class="row m-0 mt-4">
          <div class="col-5">
            <img v-if="salon.avatar" :src="salon.avatar">
          </div>
          <div class="col-7">
            <p class="text-end "><a :href="'tel: '+ salon.phone" class="text-decoration-none">
              <span class="grey-text"> {{ salon.phone }}</span>
              <span class="p-2 rounded-pill primary-btn-background secondary-text ms-2"> <i
                  class="bi bi-telephone-fill mx-auto p-1"></i></span>
            </a></p>
          </div>
        </div>
      </div>
      <div>
        <div class="d-grid gap-2 mx-auto mt-5">
          <router-link class="btn btn-primary mt-2" :to="{name:'salonTime',params:{salon:salon.userName}}">
            {{ FA_LAN.BUTTON.RESERVE }}
          </router-link>
          <router-link class="btn btn-dark mt-2" :to="{name:'salonModel',params:{salon:salon.userName}}">
            {{ FA_LAN.BUTTON.MODELS }}
          </router-link>
          <router-link class="btn btn-link btn-sm mt-2 grey-text small text-start text-decoration-none"
                       :to="{name:'qr',params:{salon:salon.userName}}">
            {{ FA_LAN.BUTTON.RECIEVE_QR }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseHeader from "../../components/BaseHeader";
import {computed} from "vue";
import persian from "../../fa-IR/fa-ir";
import {useStore} from "vuex";

export default {
  name: "Main",
  components: {BaseHeader},
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    const salon = computed(() => store.getters["salon/get"])

    function getSex(sex) {
      if (sex === 'm') {
        return FA_LAN.MAIN.MALE;
      } else {
        return FA_LAN.MAIN.FEMALE;
      }
    }

    return {FA_LAN, salon, getSex}
  }
}
</script>

<style scoped>

</style>