<template>
  <div>
    <div class="row justify-content-center mt-5">
      <div class="grey-dark-text text-center">{{ FA_LAN.FORM.LOGIN_DESCRIPTION }}</div>
    </div>
    <div class="container all-card-size mt-5 all-card-size">
      <div class="mb-3">
        <label class="form-label purple-text">{{ FA_LAN.FORM.PHONE }}</label>
        <input type="number" class="form-control" v-model.lazy="phone">
        <div class="form-text text-danger" v-if="wrongPhone">{{ FA_LAN.FORM.WRONG_PHONE }}</div>
      </div>
      <div class="d-grid gap-2 mt-4">
        <button class="btn btn-primary" @click="sendOtp" :disabled="loading">
          <div class="spinner-border spinner-border-sm" role="status" v-if="loading"></div>
          {{ FA_LAN.FORM.SEND_OTP_BT }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../../fa-IR/fa-ir";
import {ref} from "vue";
import {get, recaptchChek} from "../../store/mainRoute";
import {sweetAlert} from "../../js/alert/sweetAlert";
import {useStore} from "vuex";
import {useReCaptcha} from "vue-recaptcha-v3";


export default {
  name: "SendSms",
  setup() {
    const FA_LAN = persian;
    const phone = ref('');
    const loading = ref(false);
    const wrongPhone = ref(false);
    const store = useStore();
    const {executeRecaptcha, recaptchaLoaded} = useReCaptcha();
    const tokenValid = ref(false);

    async function sendOtp() {
      checkPhone();

      if (wrongPhone.value) {
        return
      }

      loading.value = true

      await recaptcha();

      if (!tokenValid.value) {
        loading.value = false;
        return;
      }

      await get(`v1/otp/sendOTP?phone=0${phone.value}`)
          .then(function (response) {
            if (response.data.code === 200) {
              store.commit('apiKey/setCodeSent', phone.value);
            } else {
              sweetAlert('error', response.data.description)
            }
          })
          .catch(function (error) {
            sweetAlert('error', error)
          })
      loading.value = false
    }

    async function recaptcha() {

      // (optional) Wait until recaptcha has been loaded.
      await recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await executeRecaptcha('login')

      // Do stuff with the received token.
      await recaptchChek(token)
          .then(function (response) {
            if (response.data.success === true && response.data.score >= .5) {
              tokenValid.value = true;

            } else {
              sweetAlert('error', FA_LAN.FORM.RECAPTCHA_ERROR)
              tokenValid.value = false;

            }
          })
    }

    function checkPhone() {
      wrongPhone.value =phone.value.toString().length !== 10;
    }

    return {FA_LAN, phone, loading, wrongPhone, sendOtp}
  }
}
</script>

<style scoped>

</style>