<template>
  <div></div>
  <footer class="footer mt-auto fixed-bottom text-center ">
    <div class="container bg-white py-1 all-card-size">
      <hr class="p-0 m-0 mb-1">
      <router-link class="btn btn-link btn-sm text-muted" :to="{name:'aboutUs'}">
        {{ FA_LAN.MAIN.ABOUT_US_TITLE }}
      </router-link>
      <router-link class="btn btn-link btn-sm text-muted" :to="{name:'salonHelp'}">
        {{ FA_LAN.MAIN.SALON_GUID_TITLE }}
      </router-link>
      <router-link class="btn btn-link btn-sm text-muted" :to="{name:'profile'}">
        {{ FA_LAN.MAIN.PROFILE_MAIN_TITLE }}
      </router-link>
    </div>
  </footer>

</template>

<script>
import persian from "../fa-IR/fa-ir";

export default {
  name: "Footer",
  setup() {
    const FA_LAN = persian;

    return {FA_LAN}
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>