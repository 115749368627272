import {post} from "../mainRoute";

export const profile = {
    namespaced: true,
    state: {
        profile: {}
    },
    mutations: {
        set(state, profile) {
            state.profile = profile
        },
        remove(state) {
            state.profile = {}
        }
    },
    getters: {
        get(state) {
            return state.profile
        },
    },
    actions: {
        async getProfile(context) {
            await post("v1/all/getProfile", null)
                .then(function (response) {
                    if (response.data.code !== 200) { //if apikey wrong
                        context.commit('apiKey/remove', null, {root: true})
                        context.commit('remove');
                    } else {
                        context.commit("set", response.data.data[0]);
                    }
                })
                .catch(function () {
                    //
                })
        }
    }
}