const form = {
    //search salon
    SALON_USER_NAME: "نام کاربری آرایشگاه",
    EMPTY_USER_NAME:"لطفا نام کاربری آرایشگاه را وارد نمایید",
    SALON_NOT_FOUND:"نام کاربری صحیح نمی باشد، آرایشگاه یافت نشد",


    //login
    LOGIN_DESCRIPTION:"برای رزرو نوبت باید شماره موبایل خود را وارد کنید",
    PHONE:"شماره موبایل",
    SEND_OTP_BT:"دریافت کد ورود",
    WRONG_PHONE:"لطفا شماره همراه را کامل و صحیح وارد کنید",
    RECAPTCHA_ERROR:"کد ریکپچا تایید نشد، لطفا صفحه را رفرش کنید",
    CODE_CHECK:"تایید",
    CODE:"کد ورود",
    WRONG_OTP:"لطفا کد ورود 6 عددی را صحیح و کامل وارد کنید",
    ENTER_CODE:"لطفا کد ارسال شده به شماره 0phone را وارد کنید",
    WRONG_PHONE_ENTERED:"َشماره اشتباه است؟",
    CHANGE_PHONE:"َتغییر شماره",
    WRONG_ROLE:"َشما قبلا به عنوان آرایشگاه ثبت نام کرده اید",

    //reserve Time
    NOT_TIME:"زمانی انتخاب نشده است",

    //update profile
    NAME:"نام و نام خانوادگی" ,
    SEX:"جنسیت",
    CONFIRM_BTN:"تایید",
    MALE:"مرد",
    FEMALE:"زن",
    NAME_EMPTY:"لطفا نام و نام خانوادگی را کامل وارد نمایید",
    SEX_EMPTY:"لطفا جنسیت را انتخاب نمایید",

}

export default form