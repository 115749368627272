import {post} from "../mainRoute";
import {sweetAlert} from "../../js/alert/sweetAlert";

export const model = {
    namespaced: true,
    state: {
        model: []
    },
    mutations: {
        set(state, model) {
            state.model = model
        },
        add(state, model) {
            state.model.push(...model)
        },
        remove(state) {
            state.model = []
        }
    },
    getters: {
        get(state) {
            return state.model
        },
        filter: (state) => (id) => {
            return state.model.find(model => model.id == id);
        }
    },
    actions: {
        async getModel(context, {userName, index}) {
            await post(`v1/person/getHairModelList?index=${index}`, {userName: userName})
                .then(function (response) {
                    if (response.data.code !== 200) {
                        sweetAlert('error', response.data.description)
                    } else {
                        if (index === 0) {
                            context.commit("set", response.data.data);
                        } else {
                            context.commit("add", response.data.data);
                        }
                    }
                })
                .catch(function (error) {
                    sweetAlert('error', error)
                })
        }
    }
}