<template>
  <div>
    <PageHeader :title="FA_LAN.MAIN.PROFILE_MAIN_TITLE"/>
    <div class="container all-card-size mt-3">
      <div v-if="empty">
        <div class="shadow-sm p-2 row">
          <div class="col-3">
            <img v-if="profile.avatar" :src="profile.avatar"/>
            <img v-else src="../../images/avatar.jpg"/>
          </div>
          <div class="col-9">
            <p class="m-0 pb-2 pt-2 primary-text">{{ profile.name }}</p>
            <div class="bg-light p-2 text-center grey-dark-text rounded-2">
              {{ comma(profile.walletAmount) }}<span class="ps-2">{{ FA_LAN.MAIN.TOMAN }}</span>
            </div>
          </div>
        </div>
        <div class="d-grid gap-2 mt-4">
          <button class="btn btn-link text-danger text-start text-decoration-none" @click="logout">
            <i class="bi bi-box-arrow-right me-2"></i>
            <small> {{ FA_LAN.BUTTON.LOGOUT }}</small>
            <div class="spinner-border spinner-border-sm text-danger" role="status" v-if="formLoader"></div>
          </button>
          <hr class="m-0 p-0">
        </div>
        <div class="mt-3">
          <small class="grey-dark-text ">{{ FA_LAN.MAIN.FULL_PROFILE }}</small>
          <div class="d-grid gap-2 mt-4">
            <a class="btn btn-primary" href="https://gisoonapp.ir/app" target="_blank">
              {{ FA_LAN.BUTTON.ANDROID }}</a>
          </div>
        </div>
      </div>
      <div v-else class="d-grid gap-2 mt-4">
        <router-link class="btn btn-secondary" :to="{name:'login'}">
          {{ FA_LAN.BUTTON.LOGIN }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../../components/PageHeader";
import persian from "../../fa-IR/fa-ir";
import {useStore} from "vuex";
import {computed, onMounted, ref} from "vue";
import {numberWithCommas} from "../../js/number";

export default {
  name: "Profile",
  components: {PageHeader},
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    const profile = computed(() => store.getters["profile/get"]);
    const formLoader = ref(false);
    const empty = ref(true);

    onMounted(() => {
      checkEmpty();
    })

    async function logout() {
      formLoader.value = true;
      await store.dispatch("apiKey/logout");
      checkEmpty();
      formLoader.value = false;
    }

    function comma(price) {
      if (!price && price !== 0) {
        return
      }
      return numberWithCommas(price)
    }

    function checkEmpty() {
      empty.value = Object.keys(profile.value).length !== 0
    }

    return {FA_LAN, profile, formLoader, empty, comma, logout}
  }
}
</script>

<style scoped>

</style>