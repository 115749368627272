<template>
  <div>
    <PageHeader :title="FA_LAN.MAIN.ABOUT_US_TITLE"/>
    <div class="container all-card-size mt-4 mb-5">
      <p class="primary-text text-center">{{ FA_LAN.MAIN.DESIGNED_BY }}</p>
      <small class="grey-dark-text">{{ FA_LAN.MAIN.ABOUT_US_DES }}</small>
      <div class="d-grid gap-2 mt-4">
        <a class="btn btn-primary" href="https://gisoon.negahgroup.ir" target="_blank">
          {{ FA_LAN.MAIN.WHAT_IS_GISOON }}</a>
      </div>
      <hr>
      <div class="d-flex justify-content-between">
        <p>{{ FA_LAN.MAIN.WEBSITE_NEGAH }}</p>
        <a class="grey-dark-text text-decoration-none" href="https://negahgroup.ir"
           target="_blank">www.negahgroup.ir</a>
      </div>
      <hr>
      <p class="primary-text text-center">{{ FA_LAN.MAIN.CONTACT_US_TITLE }}</p>
      <div class="d-flex justify-content-between">
        <p>{{ FA_LAN.MAIN.SUPPORT_PHONE }}</p>
        <a class="grey-dark-text text-decoration-none" href="tel:03536291519">035-36291519</a>
      </div>
      <div class="d-flex justify-content-between">
        <p>{{ FA_LAN.MAIN.APP_PHONE }}</p>
        <a class="grey-dark-text text-decoration-none" href="tel:09306242818">09306242818</a>
      </div>
      <div class="mt-4 row col-10 mx-auto">
        <img class="col-6 p-3" src="./../images/gisoon-logo.jpg">
        <img class="col-6 p-3" src="./../images/negah-logo.jpg">
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../components/PageHeader";
import persian from "../fa-IR/fa-ir";

export default {
  name: "AboutUs",
  components: {PageHeader},
  setup() {
    const FA_LAN = persian


    return {FA_LAN}
  }
}
</script>

<style scoped>

</style>