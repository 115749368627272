<template>
  <div>
    <PageHeader :title="FA_LAN.MAIN.SALON_GUID_TITLE"/>
    <div class="container all-card-size mt-4 mb-5">
      <div>
        <p class="primary-text">{{ FA_LAN.MAIN.HOW_SALON_SUBMIT }}</p>
        <small class="grey-dark-text">{{ FA_LAN.MAIN.HOW_SALON_SUBMIT_ANSWER }}</small><br>
        <small class="grey-dark-text">{{ FA_LAN.MAIN.HOW_SALON_SUBMIT_ANSWER_AUTO }}</small>
        <div class="d-grid gap-2 mt-4">
          <a class="btn btn-primary" href="https://gisoonapp.ir/app" target="_blank">
            {{ FA_LAN.BUTTON.ANDROID }}</a>
        </div>
      </div>
      <hr>
      <p class="primary-text">{{ FA_LAN.MAIN.GISOON_FEATURES_TITLE }}</p>
      <ul class="grey-dark-text small">
        <li>{{ FA_LAN.MAIN.GISOON_FEATURES_1 }}</li>
        <li>{{ FA_LAN.MAIN.GISOON_FEATURES_2 }}</li>
        <li>{{ FA_LAN.MAIN.GISOON_FEATURES_3 }}</li>
        <li>{{ FA_LAN.MAIN.GISOON_FEATURES_4 }}</li>
        <li>{{ FA_LAN.MAIN.GISOON_FEATURES_5 }}</li>
        <li>{{ FA_LAN.MAIN.GISOON_FEATURES_6 }}</li>
      </ul>
      <div class="d-grid gap-2 mt-4">
        <a class="btn btn-primary" href="https://gisoon.negahgroup.ir" target="_blank">
          {{ FA_LAN.MAIN.WHAT_IS_GISOON }}</a>
      </div>
      <hr>
      <div class="d-flex justify-content-between mt-3">
        <small>{{ FA_LAN.MAIN.SALON_HELP }}</small>
        <router-link class="btn btn-link btn-sm grey-dark-text text-decoration-none p-0" :to="{name:'aboutUs'}">
          {{ FA_LAN.MAIN.CONTACT_US_TITLE }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../fa-IR/fa-ir";
import PageHeader from "../components/PageHeader";

export default {
  name: "SalonHelp",
  components: {PageHeader},
  setup() {
    const FA_LAN = persian


    return {FA_LAN}
  }
}
</script>

<style scoped>

</style>