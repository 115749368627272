<template>
  <div>
    <div class="offcanvas offcanvas-bottom pt-2 pb-2" tabindex="-1" :id="id"
         aria-labelledby="offcanvasBottomLabel">
      <div class="offcanvas-header justify-content-center">
        <div class="row all-card-size">
          <h6 class="primary-text text-center">{{ titleText() }}</h6>
          <p class="mt-2 grey-dark-text small">{{ FA_LAN.MAIN.CONVAS_DESCRIPTION }}</p>
          <button class="btn btn-primary mt-2" @click="$emit('reserve')" :disabled="loader">
            <div class="spinner-border spinner-border-sm" role="status" v-if="loader"></div>
            {{ FA_LAN.BUTTON.RESERVE_PAY_CONFIRM }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../../fa-IR/fa-ir";
import {numberWithCommas} from "../../js/number";

export default {
  name: "Bottom",
  props: ['id', 'price', "loader"],
  setup(props) {
    const FA_LAN = persian;

    function titleText() {
      const text = FA_LAN.MAIN.CONVAS_TITLE;

      return text.replace("price", numberWithCommas(props.price))
    }

    return {FA_LAN, titleText}
  }
}
</script>

<style>

</style>