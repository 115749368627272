import {createApp} from 'vue'
import App from './App.vue'
import store from "./store";
import router from "./router";
import VueGtag from "vue-gtag";
import {VueReCaptcha} from 'vue-recaptcha-v3';

import "bootstrap/dist/css/bootstrap.rtl.min.css";
import 'bootstrap/dist/js/bootstrap.bundle';

import "bootstrap-icons/font/bootstrap-icons.css";

import "./css/style.css";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueReCaptcha, {siteKey: '6LfFiT0cAAAAAO0jXbesbXfBtnb2O_zp6J-2Fn-S'})
app.use(VueGtag, {
    config: {
        id: "G-9WL18FVHMT", params: {
            send_page_view: false
        }
    }
});
app.mount('#app');
