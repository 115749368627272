import {createStore} from "vuex";
import {apiKey} from "./modules/apiKey";
import {salon} from "./modules/salon";
import {model} from "./modules/model";
import {profile} from "./modules/profile";

const store = createStore({
    modules: {
        apiKey,
        salon,
        model,
        profile
    }
})

export default store;