<template>
  <div class="d-flex justify-content-center mt-3 mb-3">
    <div class="spinner-border primary-text" role="status"></div>
  </div>
</template>

<script>
export default {
  name: "Loading",
}
</script>

<style scoped>

</style>