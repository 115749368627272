<template>
  <div>
    <div class="row justify-content-center mt-5">
      <div class="grey-dark-text text-center">{{ title() }}</div>
    </div>
    <div class="container all-card-size mt-5 all-card-size">
      <div class="mb-3">
        <label class="form-label purple-text">{{ FA_LAN.FORM.CODE }}</label>
        <input type="number" class="form-control input" v-model.lazy="otp">
        <div class="form-text text-danger" v-if="wrongOTP">{{ FA_LAN.FORM.WRONG_OTP }}</div>
      </div>
      <div class="d-grid gap-2 mt-4">
        <button type="submit" class="btn btn-primary"
                @click.prevent="sendOtp" :disabled="loading">
          <div class="spinner-border spinner-border-sm" role="status" v-if="loading"></div>
          {{ FA_LAN.FORM.CODE_CHECK }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../../fa-IR/fa-ir";
import {computed, ref} from "vue";
import {useStore} from "vuex";

export default {
  name: "ValidSms",
  setup() {
    const FA_LAN = persian
    const otp = ref('');
    const loading = ref(false);
    const wrongOTP = ref(false);
    const store = useStore();
    const phone = computed(() => store.getters["apiKey/getPhone"]);

    async function sendOtp() {
      checkOTP()

      if (wrongOTP.value) {
        return
      }

      loading.value = true
      await store.dispatch('apiKey/checkOtp', otp.value)
      loading.value = false
    }

    function checkOTP() {
      wrongOTP.value = otp.value.toString().length !== 6;
    }

    function title() {
      const text = FA_LAN.FORM.ENTER_CODE;
      return text.replace("phone", phone.value);
    }

    return {FA_LAN, otp, loading, wrongOTP, sendOtp, title}
  }
}
</script>

<style scoped>

</style>