<template>
  <div>
    <div>
      <PageHeader :title="FA_LAN.MAIN.LOGIN_TITLE"/>
    </div>
    <div class="container all-card-size">
      <transition name="slide-fade">
        <ValidSms v-if="otpSent"/>
        <SendSms v-else/>
      </transition>
    </div>
  </div>
</template>

<script>
import SendSms from "../components/login/SendSms";
import ValidSms from "../components/login/ValidOtp";
import persian from "../fa-IR/fa-ir";
import {useStore} from "vuex";
import {computed, onMounted, watch} from "vue";
import {useRouter} from "vue-router";
import PageHeader from "../components/PageHeader";

export default {
  name: "Login",
  components: {PageHeader, ValidSms, SendSms},
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    const router = useRouter();
    const otpSent = computed(() => store.getters["apiKey/getCodeValid"]);
    const apiKey = computed(() => store.getters["apiKey/get"]);
    const hasName = computed(() => store.getters["apiKey/hasName"]);

    watch(apiKey, () => {
      checkAPIKey();
    })

    onMounted(() => {
      checkAPIKey();
    })

    function checkAPIKey() {
      if (apiKey.value && hasName.value) { //login and profile complete
        router.back()
      } else if (apiKey.value && !hasName.value) {
        router.push({name: "updateProfile"});
      }
    }

    return {FA_LAN, otpSent, hasName,}
  }
}
</script>

<style scoped>

</style>