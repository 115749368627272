import {createRouter, createWebHistory} from "vue-router";

import SalonTemp from "./pages/salon/Temp"
import SalonMain from "./pages/salon/Main"
import SalonTime from "./pages/salon/Time";
import SalonModel from "./pages/salon/Model";
import SalomModelImage from "./pages/salon/ModelImage";

import ProfileUpdate from "./pages/profile/Update";
import ProfileTemp from "./pages/profile/Temp";
import Profile from "./pages/profile/Profile";

import SalonHelp from "./pages/SalonHelp";
import Redirect from "./pages/Redirect";
import Home from "./pages/Home";
import Login from "./pages/Login";
import AboutUs from "./pages/AboutUs";
import QrCode from "./pages/QrCode";

const routes = [
    {path: "/", name: "home", component: Home},
    {path: "/login", name: "login", component: Login},
    {path: "/aboutUs", name: "aboutUs", component: AboutUs},
    {path: "/help", name: "salonHelp", component: SalonHelp},
    {
        path: "/profile", name: "temp", component: ProfileTemp, children: [
            {path: "", name: "profile", component: Profile},
            {path: "update", name: "updateProfile", component: ProfileUpdate},
        ]
    },
    {path: "/qr/:salon", name: "qr", component: QrCode},
    {path: "/:short", name: "redirect", component: Redirect},
    {
        path: "/salon/:salon", name: "salonTemp", component: SalonTemp, children: [
            {path: "", name: "salon", component: SalonMain},
            {path: "time", name: "salonTime", component: SalonTime},
            {path: "models", name: "salonModel", component: SalonModel},
            {path: "models/:model/images", name: "salonModelImage", component: SalomModelImage},
        ]
    },

]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router;