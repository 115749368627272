<template>
  <div>
    <BaseHeader :title="FA_LAN.MAIN.GISOON"/>
    <div class="container all-card-size">
      <div v-if="!error">
        <div class="d-flex justify-content-center mt-5 mb-3">
          <div class="spinner-border" role="status"></div>
        </div>
        <p class="text-center">{{ FA_LAN.MAIN.WAITING_TO_REDIRECT }}</p>
      </div>
      <div v-else>
        <p class="mt-5 text-center">{{ error }}</p>
        <div class="d-grid gap-2 mt-5">
          <router-link class="btn btn-primary" :to="{name:'home'}">
            {{ FA_LAN.BUTTON.HOME_PAGE }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../fa-IR/fa-ir";
import {useRoute} from "vue-router";
import {onMounted, ref} from "vue";
import {post} from "../store/mainRoute";
import {sweetAlert} from "../js/alert/sweetAlert";
import BaseHeader from "../components/BaseHeader";

export default {
  name: "Redirect",
  components: {BaseHeader},
  setup() {
    const FA_LAN = persian;
    const route = useRoute();
    const code = route.params.short;
    const error = ref('');

    onMounted(() => {
      redicrect()
    })

    async function redicrect() {
      await post("v1/all/getShortLink", {shortLink: code})
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description)
              error.value = FA_LAN.MAIN.TYR_AGAIN;
            } else {
              if (response.data.data) {
                location.href = response.data.data.url

              } else {
                error.value = FA_LAN.MAIN.LINK_NOT_FOUND;
              }
            }
          })
          .catch(function (error) {
            sweetAlert('error', error)
            error.value = FA_LAN.MAIN.TYR_AGAIN;
          })
    }

    return {FA_LAN, error}
  }
}
</script>

<style scoped>

</style>