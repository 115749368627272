<template>
  <div v-if="model">
    <PageHeader :title="model.title"/>
    <div class="container all-card-size mt-4" ref='scrollComponent'>
      <p class="grey-dark-text text-center">{{ model.description }}</p>
      <p v-if="empty">{{ FA_LAN.MAIN.EMPTY_IMAGES }}</p>
      <div v-else class="mt-2 row">
        <div class="col-6 p-2" v-for="image in images" :key="image.id">
          <a :href="image.image" target="_blank"> <img class="rounded" :src="image.image"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import persian from "../../fa-IR/fa-ir";
import {computed, onMounted, onUnmounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import PageHeader from "../../components/PageHeader";
import {post} from "../../store/mainRoute";
import {sweetAlert} from "../../js/alert/sweetAlert";

export default {
  name: "ModelImage",
  components: {PageHeader},
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const scrollComponent = ref(null);
    const modelID = route.params.model;
    const model = computed(() => store.getters["model/filter"](modelID));
    const salon = route.params.salon;
    const loader = ref(true);
    const images = ref([]);
    const index = ref(0);
    const empty = ref(false);

    onMounted(async () => {
      checkModelExist();

      await getImages();
      loader.value = false;

      window.addEventListener('scroll', loadMore);
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', loadMore);
    })

    function checkModelExist() {
      if (!model.value) {
        router.push({name: "salonModel", params: {salon: salon}})
      }
    }

    async function getImages() {
      await post(`v1/person/getHairModelImages?index=${index.value}`, {id: modelID})
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description)
            } else {
              images.value.push(...response.data.data)
            }
          })
          .catch(function (error) {
            sweetAlert('error', error)
          })
      checkEmpty();
    }

    async function loadMore() {
      let element = scrollComponent.value
      if (element.getBoundingClientRect().bottom < window.innerHeight) {

        if (index.value - images.value.length > 10) { //for stop looping
          return;
        }
        loader.value = true;
        index.value += 10;
        await getImages();
      }
    }

    function checkEmpty() {
      empty.value = images.value.length === 0;
    }


    return {FA_LAN, model, loader, scrollComponent, images, empty}
  }
}
</script>

<style scoped>

</style>