<template>
  <div class="primary-background text-white p-2 pb-3 pt-3">
    <div class="container d-flex align-content-center align-items-center all-card-size">
      <button class="btn text-white btn-link p-0" @click="back"><i class="bi bi-arrow-right p-0 pe-2"></i></button>
      <span>{{ title }}</span>
    </div>
  </div>
</template>

<script>
import {useRouter} from "vue-router";

export default {
  name: "PageHeader",
  props: ['title', 'salon'],
  setup(props) {
    const router = useRouter();

    function back() {
      if (props.salon) {
        router.push({name: 'salon', params: {salon: props.salon}})
      } else {
        router.back();
      }
    }

    return {back}
  }
}
</script>

<style scoped>

</style>